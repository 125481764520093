import { ensureTrailingSlash } from "./ensureTrailingSlash";

interface EnvConfig {
  IS_PROD: boolean | null;
  API_BASE_URL: string | null;
}

declare global {
  interface Window {
    env: Partial<EnvConfig>;
  }
}

export const environment = {
  IS_PROD: false,
  API_BASE_URL: ensureTrailingSlash(window['env']['API_BASE_URL']) || 'https://pre.portal-evidencias-api.kn.finwave.es/'
};
